$cardHeight: 187px
$cardWidth: 303px

.hero
    background-color: #aaaaaa
    padding: 30px 3em 4em 3em
    max-height: 42% !important
    min-height: 300px
    box-shadow: 0px 3px 6px #00000029
    .userInfo
        color: white
        padding-top: 30px
        .mat-card-avatar
            height: 70px
            width: 70px
            border-radius: 50%
            flex-shrink: 0
            object-fit: cover
            margin-right: 10px
            border: 1px solid #fff

        mat-card
            background-color: #FFFFFF
            min-width: $cardWidth
            max-height: $cardHeight
            max-width: $cardWidth
            min-height: $cardHeight
            box-shadow: 0px 3px 6px #00000029
            // border: 1px solid #E2E2E2
            mat-card-title-group
                max-width: 96% !important
                cursor: pointer
                mat-card-title
                    margin-top: 0px
                    &.durationInfo
                        margin-top: 15px
                        margin-bottom: 9px
        h1
            font-family: Roboto-Bold, Roboto
            font-weight: bold
            font-size: 47px
            color: white
            border: none
            margin-top: 10px
            text-shadow: -2px 3px rgba(0,0,0,0.5)

        .login
            font-family: Roboto
            font-size: 1.07em
            color:#000

        .groupheader
            margin-bottom: 0px
            color:#000
        .groupname
            font-family: Roboto
            font-weight: bold
            font-size: 2em
        h2
            font-family: Roboto
            text-align: right
            color: #000
            font-size: .85em
            // color: #5f5f5f
            // font-size: 12px
            // line-height: 12px
            // width: $cardWidth
        .card-title-audio
            color: #CA2DB4
        .card-title-video
            color: #189ACC
        .card-title-document
            color: #FD7822
        .card-title-library
            color: #7524B0
        .card-title-external
            color: #3CBE20
        .card-title-roadmap
            color: #FD261C
        .favorite
            position: absolute
            top: 0
            right: 0
        .itemTitle
            font-family: Roboto, sans-serif
            font-weight: medium
            font-size: 15px
            line-height: 18px
            height: 38px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
        .duration
            width: 74px
            height: 17px
            background-color: #aaaaaa
            color: white
            font-size: 10px
            font-family: Roboto
            font-weight: normal
            padding: 5px
            text-align: center
            display: inline-flex
            align-content: center
            align-items: center
            justify-content: center
        .notStarted
            font-weight: normal
            padding: 3px 5px 5px 6px
            color:#aaaaaa
            padding: 0px
            text-align: left
            font-size: x-small
        .icon
            opacity: .1
            position: absolute
            bottom: 15px
            right: 5px
        .space
            min-width: $cardWidth
            max-height: $cardHeight
            max-width: $cardWidth
            min-height: $cardHeight
            box-shadow: 0px 3px 6px #00000029
            border: 1px solid #E2E2E2
                // min-width: 294px
            // min-height: 98px
            // border-radius: 8px
            // border-color: #c6c6c6
            // border: 1px solid #c6c6c6
            // display: grid
            // padding: 25px 25px 0px 25px
            // contain: content
            // justify-items: center
            // align-items: center
            cursor: pointer
            img
                max-width: 80%
        .description
            width: 90%
            font-family: Roboto, sans-serif
            font-size: 10px
            line-height: 10px
            height: 30px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 3
            -webkit-box-orient: vertical
        .itemProgress
            color: #aaaaaa
            font-size: x-small
            position: absolute
            bottom: 0px
            left: 0px
            width: 100%
            mat-progress-bar
                width: $cardWidth
                top: 1px
                // left: -2px
                height: 6px
                border-radius: 0px 0px 4px 4px
                .mat-progress-bar-fill::after
                    background-color: #57ABFF
                .mat-progress-bar-buffer
                    background-color: #B5B5B5
            .percentage
                font-size: 8px
                font-family: Roboto
                position: absolute
                left: 33%
                bottom: 19px
                text-transform: uppercase


.inProgress
    overflow-x: hidden
    margin-top: 35px
    min-height: 250px
    padding: 0px 10px 0px 10px
    ::-webkit-scrollbar
        display: none
    h2
        text-align: left !important
        color: #185795
        font-size: 2rem
        margin-left: 10px !important
        border: none
        font-family: Roboto, san-serif
        font-weight: bold
    .toggleProgress
        color: #57ABFF
        position: absolute
        right: 30px
        text-transform: uppercase
        font-family:  Roboto
        font-size: 12px
    .scrollList
        padding: 10px
        overflow-x: auto
        scroll-behavior: smooth
        mat-card
            background-color: #FFFFFF
            min-width: $cardWidth
            max-height: $cardHeight
            max-width: $cardWidth
            min-height: $cardHeight
            border: 1px solid #E2E2E2
            box-shadow: 0px 3px 6px #00000029
            padding: 15px
            margin-right: 12px
            mat-card-title-group
                // max-width: 96% !important
                cursor: pointer
                mat-card-title
                    margin-top: 0px
                    &.durationInfo
                        margin-top: 15px
                        margin-bottom: 9px

                button
                    width: 50px
                    height: 40px

                    .mat-icon
                        line-height: 0px

                .mat-card-subtitle
                    span
                        font-weight: normal

            &.complete
                background-color: #e6e6e6
                color: #CBCBCB
                .mat-progress-bar-fill::after
                    background-color: #185795
        h1
            color: white
            border: none
            margin-bottom: 0.25em
        .login
            margin-bottom: 2.5em
        h2
            text-align: right
            color: #CBCBCB
            font-size: 2rem
        .card-title-audio
            color: #CA2DB4
        .card-title-video
            color: #189ACC
        .card-title-document
            color: #FD7822
        .card-title-library
            color: #7524B0
        .card-title-external
            color: #3CBE20
        .card-title-roadmap
            color: #FD261C
        .favorite
            position: absolute
            top: 0
            right: 0
        .itemArchived
            font-family: Roboto, sans-serif
            color: red
            font-weight: medium
            font-size: 9px
            font-style: italic
        .itemTitle
            font-family: Roboto, sans-serif
            font-weight: medium
            font-size: 15px
            line-height: 18px
            height: 38px
            vertical-align: middle
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
        .duration
            width: 74px
            height: 17px
            background-color: #aaaaaa
            color: white
            font-size: 10px
            font-family: Roboto
            font-weight: normal
            padding: 5px
            text-align: center
            display: inline-flex
            align-content: center
            align-items: center
            justify-content: center
        .notStarted
            font-weight: normal
            padding: 3px 5px 5px 6px
            color:#aaaaaa
            padding: 0px
            text-align: left
            font-size: x-small
        .itemProgress
            color: #aaaaaa
            font-size: x-small
            position: absolute
            bottom: 0px
            left: 1px
            width: 100%
            mat-progress-bar
                height: 6px
                width: $cardWidth
                top: 1px
                left: -2px
                border-radius: 0px 0px 4px 4px
                .mat-progress-bar-fill::after
                    background-color: #57ABFF
                .mat-progress-bar-buffer
                    background-color: #B5B5B5
            .percentage
                font-size: 8px
                font-family: Roboto
                font-weight: normal
                position: absolute
                left: 33%
                bottom: 19px
                text-transform: uppercase
            &.complete
                .mat-progress-bar-fill::after
                    background-color: #185795

        .icon
            opacity: .1
            position: absolute
            bottom: 15px
            right: 9px
        .space
            background-color: white
            min-width: $cardWidth
            max-height: $cardHeight
            max-width: $cardWidth
            min-height: $cardHeight
            margin-right: 12px
            box-shadow: 0px 3px 6px #00000029
            // border: 1px solid #E2E2E2
            // width: 294px
            // max-height: 134px
            border-radius: 8px
            // border-color: #c6c6c6
            border: 1px solid #c6c6c6
            display: grid
            padding: 25px 25px 0px 25px
            contain: content
            justify-items: center
            align-items: center
            cursor: pointer
            img
                max-width: 80%
        .description
            width: 90%
            font-family: Roboto, sans-serif
            font-size: 10px
            line-height: 10px
            height: 30px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 3
            -webkit-box-orient: vertical
        .more
            height: $cardHeight
            width: 34px
            cursor: pointer
            background-color: rgba(7,7,7,0.4)
            position: absolute
            right: 34px
            margin-right: -34px
            display: inline-flex
            align-content: center
            align-items: center
            justify-content: center
            &:hover
                background-color: rgba(7,7,7,0.6)
            &.spaces
                // height: 134px

        .less
            height: $cardHeight
            width: 34px
            cursor: pointer
            background-color: rgba(7,7,7,0.4)
            position: absolute
            left: 34px
            margin-left: -34px
            display: inline-flex
            align-content: center
            align-items: center
            justify-content: center
            z-index: 2
            &:hover
                background-color: rgba(7,7,7,0.6)
            &.spaces
                // height: 134px

/* Viewports between 600 and 959px wide */
@media only screen and (min-width: 500px) and (max-width: 959px)
    .hero .userInfo h1
        font-size: 4em !important

/* Viewports between 0 and 499px wide */
@media only screen and (max-width: 499px)
    .hero
        padding: 2em  3em 4em  2em
        min-height: 240px

        .userInfo
            h1
                font-size: 2.5em !important

            .login
                font-size: 0.85em !important

            .groupname
                font-size: 1.2em !important

            .mat-card-avatar
                width: 50px
                height: 50px
