@use "sass:math"

#delphire-nav-bar
  top: 0
  z-index: 5
  position: fixed
  height: 45px
  align-content: 'center'
  background-color: #FAF9FA
  box-shadow: 0px 3px 3px rgba(0,0,0,0.25)

  .mat-drawer-backdrop
    position: fixed

  .version
    bottom: 20px
    right: 20px
    position: absolute
    font-size: 10px
    font-family: Roboto
    color: #a3a3a3
    display: block
    line-height: 10px
    text-align: center

  .nav-button
    max-height: 30px
    min-width: auto
    line-height: 30px
    border: none

    svg
      width: 20px
      height: 20px

    .menu-text
      font-size: 10px
      padding-right: 10px
      text-transform: uppercase

    &.back-button
      padding-left: 10px
      font-size: 10px
      text-transform: uppercase

    &.home-button
      text-transform: uppercase

    &.menu-button
      padding: 0 10px

  .nav-divider
    margin: 0 10px

  .nav-bar-search
    text-align: right

  .search-container
    width: 100%

    .mat-form-field
      margin-right: 10px
      width: 200px
      transition: width 0.5s ease

      &.mat-focused
        width: 95%

        ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex
          background-color: red
          background-color: #E6F2FF

  .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content
    top: 2px
    right: 2px

  .space-logo
    display: inline-block
    width: 95px
    height: 100%
    object-fit: fill
    align-items: center
    align-content: center
    justify-content: center
    padding: 5px
    margin-left: 10px
    flex-direction: column

    img
      width: 100%

  .mat-sidenav
    @include font-tg-regular()
    color: white
    overflow-y: auto
    padding: 5px 0
    width: 336px
    max-width: 336px
    background: transparent linear-gradient(180deg, #185795 0%, #0d2e4e 100%) 0% 0% no-repeat padding-box
    color: white
    height: fit-content
    min-height: 50%
    padding-bottom: 45px
    padding-top: 0

    .mat-list-base
      padding-top: 0

    .layout-padding
      padding: 20px

    a
      color: white
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      display: list-item
      font-size: 0.7em

      &:hover
        background: none !important
        background-color: rgba(0,0,0,0.25) !important

      ng-icon
        height: auto
        margin: 0 10px

      .badge
        margin-left: 5px

  .mat-divider-vertical
    height: 35px
    position: absolute
    top: 6px
    border-color: #e0e0e0
    display: inline-flex

  .flyout-user-info
    align-items: center
    img.grav
      border-radius: 50%
      width: 72px
      height: 72px
      overflow: hidden
      display: inline-block
      vertical-align: middle
      @media (max-width: $screen-sm - 1)
    h4
      color: white
      text-align: left
      @include font-tg-regular()
      font-size: 1em


/* Viewports between 0 and 720px wide */
@media only screen and (max-width: 720px)

  #delphire-nav-bar
    .mat-button
      padding: 0 6px

    .nav-button.back-button
      padding-left: 0

    .search-container
      .mat-form-field
        width: 90%
        transition: none

        &.mat-focused
          width: 90%

    .mobile-search
      position: absolute
      z-index: 2
      right: -100%
      width: 100%
      height: 100%
      padding-top: 8px
      padding-left: 8px
      transition: right 0.5s ease

      .search-component-container
        background-color: #FAF9FA

      &.visible
        right: 0
