@import "login"
@import "dashboard"
@import "quizzes"
// @import "roadmap"

// override widget tile description style
.widget .widget-label
  font-size: 1em !important
  
.widget .widget-description 
  text-transform: none !important
  font-size: 0.8em !important
  padding: 10px 5px 5px 5px !important
  line-height: 1.5em !important

body
  background-image: url('/brand/sharp/assets/images/splash.png')
  background-color: #fff

.home, .space-view, .layout-view
  background-image: url('/brand/sharp/assets/images/background.jpg') !important
  background-repeat: no-repeat !important
  background-size: cover !important

.modal.fade.ng-isolate-scope.modal-video.in .modal-dialog
  width: 50% !important
// --- if iphone media query is needed ---
@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
  .navbar-fixed-top .navbar-search
    right: 3.7em
  .navbar-fixed-top .navbar-logo-tenant
    transform: scale(0.6, 0.6)!important
  .disclaimer
    // font-size: 0.36em !important
  .modal-quiz
    quiz-question
      .quiz-buttons
        button.btn-lg
          font-size: 12px !important
  .modal-quiz
    .quiz-buttons
      bottom: 15% !important
  .modal-quiz
    .quiz-question
      height: 71% !important
.iphone-toolbar-content
  .flex-10
    max-height: 40px
    .navbar-back-to-delphire > li > a
      padding-top: 4px

// color a caret
.md-select-value .md-select-icon:after
  color: #04407D !important

// prevent genentech from making font sizing decisions...
.new-ui.library .aside .bottom .file-description .description 
  h1, h2, h3, h4, h5, h6, li, blockquote
    font-size: 0.8em !important
    font-weight: normal !important

// need to see the link
.roadmap-item-description
  p 
    color: #000 !important
    a 
      color: #195695 !important
      text-decoration: underline !important
    a:active, a:hover
      color: #195695 !important
      text-decoration: underline !important
      
.course-builder-item-description
  p 
    color: #000 !important
    a 
      color: #195695 !important
      text-decoration: underline !important
    a:active, a:hover
      color: #195695 !important
      text-decoration: underline !important

// footer colors and side nav
footer .disclaimer, footer .support
  background-color: #F26707 !important
footer .footer-bottom .f1
  background-color: #E93725 !important
footer .footer-bottom .f2
  background-color: #F68825 !important
footer .footer-bottom .f3
  background-color: #F05123 !important
.id-dashboard.grid md-grid-list md-grid-tile figure
  background: transparent linear-gradient(147deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box
  border: 1px solid #E93725
#delphire-nav-bar .navbar-nav li .dropdown-menu
  background: transparent linear-gradient(180deg, #E93725 0%, #F68825 100%) 0% 0% no-repeat padding-box

@media only screen and (min-device-width: 375px) and (max-device-width: 1024px)
  .my-learnings
    .header-table
      .table-container
        -webkit-overflow-scrolling: touch
        // height: 480px

        .learning-item-progress
          .md-bar2
            height: 7px !important
