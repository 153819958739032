$brand-color-primary: #082b67    // button, headline
$brand-color-secondary: #3273ff  // header
$brand-color-default: #8cabca    // secondary buttons, answer frames
$brand-color-highlight: #baccdf  // selected state
$white-text: #fff  							 // use this to replace all white text colors

.modal-quiz
	// .quiz-brand-header
	// 	background: $brand-color-secondary !important
	// 	color: $white-text  !important

	.quiz-question
		margin-top: 10px !important

	.quiz-question
		h1, h2, h3, h4
			color: #000 !important

	.quiz-question
		h4
			font-weight: 400 !important

	.quiz-brand-btn-primary
		// background-color: $brand-color-primary !important
		color: $white-text !important
		background-image: none
		width: auto

	.quiz-brand-btn-default
		// background-color: $brand-color-default !important
		border: 0px
		color: $white-text !important
		background-image: none

	.modal-body
		background: url(/images/quizbackground.png) no-repeat center

	.quiz-incorrect
		background: url(/images/incorrect.png) no-repeat center
		width: 100px
		height: 100px
		margin: 0 auto
		margin-top: 15px
		background-size: 100px 100px

	.quiz-incorrect-sm
		display: none
		background: url(/images/incorrect.png) no-repeat center
		width: 40px
		height: 40px
		margin: 0 auto
		background-size: 40px 40px

	.quiz-correct
		background: url(/images/correct.png) no-repeat center
		width: 100px
		height: 100px
		margin: 0 auto
		margin-top: 15px
		background-size: 100px 100px

	.quiz-correct-sm
		display: none
		background: url(/images/correct.png) no-repeat center
		width: 40px
		height: 40px
		margin: 0 auto
		background-size: 40px 40px

	.quiz-description
		h1, h2, h3, h4
			color: $brand-color-primary !important
			border-bottom: 0px !important

	.answer
		border-bottom: 1px solid #ccc !important

	.answer-indicator
		background-color: none

	// .answer.selected
	// 	border: 2px solid $brand-color-highlight !important
	// 	background-color: none
	// 	color: #000

		.answer-indicator
			color: $brand-color-highlight !important
			background-color: $white-text !important