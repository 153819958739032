/* main navigation for favorites */
.navbar-fixed-top
  .navbar-right
    li a
      padding-left: 1.75em
    .favorites-icon
      color: $brand-warning
      margin-left: calc(-1.286em - 4px)
      font-size: 0.8em
      margin-right: 4px
    .badge
      float: right
      margin-right: -1.286em
      font-weight: normal
      background-color: $brand-warning
    .disabled
      pointer-events: none

%aside
  border: none
  border-left: 1px solid #707070

  &.no-selection
    .make-selection
      height: 100%
      padding: 0 3em
      @include font-tg-bold-cond()
      text-align: center

  .top
    min-height: 90px
    padding: 1em
    @include font-bold()
    color: white
    background: transparent linear-gradient(180deg, #195695 0%, #000000 100%) 0% 0% no-repeat padding-box

    .name
      font-size: 1em
      line-height: 0.95em

    .last-updated
      font-size: 0.8em

  .bottom
    padding: 1em
    @include font-default()
    color: black

    .file-description
      .description-header
        @include font-bold()
        font-size: 1em
      .description
        font-size: 0.8em

    .preview
      text-align: center
      margin-bottom: 1em

      .preview-image, i
        width: 50%
        margin: 0 25%
        border: 1px solid #707070

      i
        width: auto
        margin: none
        border: none

    .btn
      height: 25px
      max-height: 25px
      min-height: 25px
      min-width: 50px
      color: white
      font-size: 0.6em
      line-height: 0.9em
      border-radius: 5px
      display: inline-flex !important

    .favorite
      text-align: center
      margin-bottom: 0.5em
      .btn-favorite
        background-color: #70C6DD
        line-height: 0.9em

    .actions
      &.external
        text-align: center

      .btn-start
        background-color: #1B9EC6
      .btn-resume
        background-color: #F3981E
      .btn-download
        background-color: #AEAEAE


    .file-info
      padding-top: 1em
      .file-header
        @include font-bold()
        font-size: 1em

      .user-progress
        padding-bottom: 1.25em
        .percent
          font-size: 0.7em

        md-progress-linear
          height: 12px

          .md-container
            background-color: white

          .md-mode-determinate
            height: 12px
            border-radius: 3px
            border: 1px solid #707070

            .md-bar
              height: 12px
              background-color: #B2BB21

      .info
        .header,
        .value
          @include font-default()
          font-size: 0.8em
          text-transform: capitalize

.library

  .label
    padding: .2em .6em .3em
    font-size: 100% !important
    font-weight: normal !important
    color: #000 !important

  .library-nav
    position: relative
    z-index: 10
    margin-bottom: 0
    .navbar-nav
      padding-right: 5px
      border-right: 1px solid #eee
      margin-right: 5px
      &:last-child
        border-right: none
        margin-right: 0
        padding-right: 0
    .navbar-back
      margin-left: -15px
      margin-right: 15px
    li
      a
        cursor: pointer
        transition: color 0.1s linear
        &.active
          color: $brand-success
        i
          vertical-align: middle
        .fa-stack
          line-height: 1em
          height: 1em
          width: 1.3em
          &.sort-wider
            width: 1.4em
          .fa
            text-align: right
          .fa:first-child
            text-align: left

  .library-item-hold
    position: absolute
    top: $navbar-height // makes up for library nav
    right: 0
    bottom: 0
    left: 0
    overflow: auto
    -webkit-overflow-scrolling: touch

  .library-item
    float: left
    margin: 15px

  .library-item-nav
    pointer-events: none
    .library-nav-up
      position: absolute
      top: 50px
      left: 50%
      transform: translateX(-50%)
      pointer-events: auto
    .library-nav-down
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      pointer-events: auto

  &.new-ui
    padding: 1em 5em
    overflow-y: auto

    @media (max-width: $screen-sm - 1)
      padding: 1em 1em 8em 1em

      table.md-table:not(.md-row-select) td.md-cell:first-child, table.md-table:not(.md-row-select) th.md-column:first-child
        padding: 0 8px

    .library-description
      margin-bottom: 2em
      color: black
      font-size: 1em

    .md-content
      background: none
      padding: 0
      border: 1px solid #707070

      .mat-selected
        background-color: #7E8083
        color: white

        .mat-cell
          color: white

      td > i
        font-size: 20px
        padding-left: 10px

      &.aside
        @extend %aside
        @media (max-width: $screen-sm - 1)
          display: none

      mat-table
        background: white
        mat-cell
          cursor: pointer
        mat-header-row
          background-color: #5C666F

          mat-header-cell
            span
              color: white

            .md-sort-icon
              color: white
.mat-bottom-sheet-container
  padding: 0 !important
.bottom-sheet
  margin: 0
  padding-right: 0
  padding-left: 0
  .md-content .mobile-aside
    @extend %aside
    @media (max-width: $screen-sm - 1)
      display: block
  .top
    min-height: 90px
    padding: 1em
    @include font-bold()
    color: white
    background: transparent linear-gradient(180deg, #195695 0%, #000000 100%) 0% 0% no-repeat padding-box
  .file-info
    padding: 1em
    .file-header
      @include font-bold()
      font-size: 1em
  .btn
    height: 25px
    max-height: 25px
    min-height: 25px
    min-width: 50px
    color: white
    font-size: 0.6em
    line-height: 0.9em
    border-radius: 5px
    display: inline-flex !important
  .actions
    &.external
      text-align: center

    .btn-start
      background-color: #1B9EC6
    .btn-resume
      background-color: #F3981E
    .btn-download
      background-color: #AEAEAE

#delphire-library
  .mat-paginator-outer-container
    background-color: #5C666F
    color: #fff
    font-size: 1em
    border-radius: 10px

    .mat-paginator-page-size-label, .mat-paginator-range-label
      font-size: 1.2em
    
    .mat-select-min-line, .mat-select-arrow-wrapper, .mat-select-arrow, .mat-form-field-underline
      color: #fff !important

    .mat-form-field-flex, .mat-form-field-infix
      border-bottom: 1px solid #fff