@import 'app/vars'
@import "app/fonts"
@import 'app/mixins'
@import 'vendors/font-awesome'
@import 'vendors/bootstrap'
@import 'vendors/google-fonts'
@import 'vendors/delphire-icons'
@import 'app/base'
@import 'app/roadmap-task'
@import 'app/widget'
@import 'app/modals'
@import 'app/library'
@import 'app/quiz'
@import 'app/top-navigation'
@import 'app/footer'
@import 'app/my-learnings'
@import 'app/notifications'
@import 'app/home-page'
@import 'app/favorites-dialog'
@import 'app/disclaimer'
@import '@angular/cdk/overlay-prebuilt.css'

@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"

iframe#launcher
  width: 10% !important
  margin: 0px 40px 0 0 !important
  padding: 0 !important
  opacity: 0 !important
  display: inline-block !important
  text-align: left !important

body
  padding: 0px !important
  overflow-y: hidden
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
p
  margin: 0 0 10px
.linkSnackBar
  .mat-simple-snackbar-action button
    color: #a4a4a4
.container
  display: flex
  flex-direction: row
  max-width: 100% !important
  padding:0 !important
  width:100%
.carousel-control-prev, .carousel-control-next
  width:5% !important

.carousel-indicators
    z-index: 1
    .active
        width:30px !important
        height:3px !important

app-space, app-layout, app-layout-detail

.layout-detail-view
    position: absolute
    top:45px
    right:0
    left:0
    bottom:55px
    overflow: auto
    height: calc(100vh - 100px) !important
    padding: 0 2px !important

.space-view, .layout-view, .home
    position: absolute
    top:45px
    right:0
    left:0
    bottom:0
    overflow: auto
    padding-bottom: 100px
    .home-view
      padding: 0
      height: 100vh
      padding-bottom: 100px
      h1
        font-size: 5.2em

    h2
        text-align: center
        margin: 10px
        padding: 5px
        font-size: 24px
        font-family: Roboto, sans-serif
    mat-grid-list
        margin:0 20px 0 0


    mat-grid-tile
        cursor: pointer
        margin: 10px
        box-shadow: 0px 25px 50px rgba(0,0,0,0.1)
        border: 1px solid #70C6DD
        border-radius: 10px
        background: transparent linear-gradient(147deg, #FFFFFF 0%, #E6F9FF 100%) 0% 0% no-repeat padding-box
.roadmap, .library, .quiz, .course-builder, .my-learnings, .notifications, .home-view
    position: absolute
    left:0
    right:0
    top:0
    bottom:55px
    height: fit-content
    color: black
    font-size: 14px
    overflow: auto
    padding-bottom: 150px
    text-align: left
    z-index: 2

    h1
      font-size: 2em
      font-weight: normal
      margin: 0

    h2
      font-size: 1.5em
      margin: 0

    h3
      font-size: 1.25em
      margin: 0
      padding: 0 0 10px 0

    h4
      font-size: 1em
      margin: 0.5em 0
      margin: 0
      padding-bottom: 7px

.roadmap-wrapper
  overflow: hidden
  max-width: 750px
  margin: 0 auto

/* ------------------------------------------------

.roadmap-tasks-empty
  padding: 0.5em 1.0em

/* ------------------------------------------------

.roadmap-header
  padding-top: 30px

  p
    padding: 5px 0 0 0
    margin: 0

/* ------------------------------------------------

.roadmap-nav-level-1
  padding: 30px 0

  md-input-container
    margin: 0

  md-select
    display: inline-block
    min-width: 200px
    margin: 0
    font-size: 20px
    font-weight: bold

    &.md-default-theme .md-select-value.md-select-placeholder, .md-select-value.md-select-placeholder
      color: #000 !important

/* ------------------------------------------------

.roadmap-objectives-level-1
  padding: 0 0 15px 0

/* ------------------------------------------------

.roadmap-nav-level-2
  padding: 20px 15px

  md-select
    display: inline-block
    min-width: 200px
    margin: 0
    font-size: 16px
    font-weight: bold

    &.md-default-theme .md-select-value.md-select-placeholder, .md-select-value.md-select-placeholder
      color: #000 !important

  md-radio-button
    display: inline-block
    min-width: 200px

    &.md-default-theme.md-checked .md-off, &.md-checked .md-off
      border-color: rgba(10, 86, 150, 0.87)

    &.md-default-theme .md-on, .md-on
      background-color: rgba(10, 86, 150, 1)

md-radio-group
  &.md-default-theme .md-checked .md-ink-ripple, .md-checked .md-ink-ripple
    color: rgba(10, 86, 150, 0.26)

.roadmap-nav-level-2 md-radio-button .md-label
  position: relative
  top: -3px

/* ------------------------------------------------

.roadmap-objectives-level-2
  padding: 0 15px 10px 15px

.roadmap-border-bottom
  border-bottom: 1px solid #7E8083

/* ------------------------------------------------

.roadmap-content-box
  border: 1px solid #7E8083
  border-radius: 10px
  overflow: hidden

.md-padding
    padding:16px

.roadmap-item
  text-align: left
  background: transparent linear-gradient(98deg, #FFFFFF 0%, #EBF5FF 100%) 0% 0% no-repeat padding-box
  border-bottom: 1px solid #7E8083


  div:last-child
    border-bottom: none

  &:hover
    background: transparent linear-gradient(98deg, #FFFFFF 0%, #FFF2E0 100%) 0% 0% no-repeat padding-box

  h4
    font-size: 1.2em
    margin: 0
    padding-bottom: 7px

.roadmap-item-capitalize
  text-transform: capitalize


.roadmap-item-description
  font-size: 14px
  line-height: 1.2em
  padding: 0 0 7px 0
  margin:0
  color: #666

  p
    margin: 0px
    padding: 0px
    margin: 0.5em 0

.roadmap-item-favorite
  padding-top: 10px

.roadmap-is-favorite
  color: gold

.roadmap-not-favorite
  color: #d0d0d0

.hand
  cursor: pointer

.roadmap-item-details
  font-size: 0.8em

.roadmap-item-progress
  margin: 8px 0 5px 0


  .percent
    font-size: 0.7em
    margin: 5px 0

  mat-progress-linear
    height: 12px
    margin-left: -2px

  .mat-container
    background-color: white

  .mat-mode-determinate
    height: 12px
    border-radius: 3px
    border: 1px solid #707070

    .mat-bar
      height: 12px
      background-color: #B2BB21

.roadmap-item-actions
  text-align: center
  padding-left: 16px
  padding: 8px !important

.roadmap-btn
  // height: 25px
  // max-height: 25px
  // min-height: 25px
  // min-width: 50px
  // font-size: 0.6em
  // line-height: 25px !important
  // border-radius: 5px
  // text-align: center
  text-transform: uppercase
  text-align: center
  color: white
  width: 160px
  height: 25px
  font-size: 0.8em
  font-weight: normal
  line-height: 1em !important

  &.roadmap-btn-loading
    color: white
    background-color: #CCCCCC

    &:hover, &.md-focused
      color: white
      background-color: #CCCCCC

  &.roadmap-btn-start
    color: white
    background-color: #195695
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #04407d !important

  &.roadmap-btn-resume
    color: white
    background-color: #F3981E
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #d37700 !important

  &.roadmap-btn-complete
    color: white
    background-color: #70C6DD
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #309cb9 !important

  &.roadmap-btn-self-complete
    color: #195695
    background-color: #fff
    border: 1px solid #195695

    &.completed
      background-color: #B3BB26
      color: white
      border: none

      &:hover, &.md-focused
        background-color: #9ca320 !important

    &:hover, &.md-focused
      background-color: #F8F8F8 !important

  &.roadmap-btn-download
    color: white
    background-color: #AEAEAE
    margin-bottom: 10px

    &:hover, &.md-focused
      background-color: #7e7373 !important

  &.roadmap-btn-favorite
    height: 25px
    max-height: 25px
    min-height: 25px
    min-width: 50px
    margin: 0
    font-size: 0.6em
    line-height: 25px
    border-radius: 5px
    text-align: center
    color: white
    background-color: #70C6DD

    &:hover, &.md-focused
      background-color: #309cb9 !important

md-content.roadmap-item.text-only
  padding-top: 30px
  padding-bottom: 23px

  .roadmap-item-details, .roadmap-item-progress, .roadmap-item-actions
    display: none

@media (max-width: 599px)
  .roadmap-item-favorite
    padding-top: 0

  .roadmap
    padding: 1em 1em 8em 1em

  .roadmap-btn, .roadmap-item-progress
    margin: 1em 0

//   .roadmap-btn
//     height: 45px
//     max-height: 45px
//     min-height: 45px
//     min-width: 150px
//     font-size: 1em
//     line-height: 45px

  // .roadmap-item
  //   padding: 0

  // .roadmap-item-info
  //   padding: 1em

//   .roadmap-item-actions
//     &.start
//       background-color: #EBFBFF

//     &.resume
//       background-color: #FFF2E0

//     &.complete
//       background-color: #E8F3FF

md-progress-circular.btn-loader
  display: inline-block
  margin: 5px 5px 0 0

.loader-span
  vertical-align: super

md-progress-circular
  &.md-default-theme path, path
    stroke: white

.course-builder
  background: transparent
  color: black
  font-size: 14px
  overflow: auto
  padding-top: 30px

  h1
    font-size: 1.75em

  h2
    font-size: 1.5em

  h3
    font-size: 1.25em

  h4
    font-size: 1em
    margin: 0.5em 0

.course-builder-wrapper
  overflow: hidden
  max-width: 750px
  margin: 0 auto

.course-builder-body
  font-size: 1em
  line-height: 1.2em
  margin-bottom: 2em

.course-builder-item
  background: transparent linear-gradient(98deg, #FFFFFF 0%, #EBF5FF 100%) 0% 0% no-repeat padding-box
  border: 1px solid #7E8083
  border-radius: 10px
  margin-bottom: 1em
  position: relative
  overflow: auto

  &:hover
    background: transparent linear-gradient(98deg, #FFFFFF 0%, #FFF2E0 100%) 0% 0% no-repeat padding-box

  &.text-only
    background: none
    border: none
    border-radius: 0
    margin-top: 1em

    .course-builder-item-description
      font-size: 1.1em

.course-builder-item-capitalize
  text-transform: capitalize

.course-builder-item-description
  font-size: 0.9em
  line-height: 1.2em
  margin-bottom: 0.5em
  font-size: 0.9em

.course-builder-item-details
  font-size: 0.8em

  .course-builder-item-type span
    vertical-align: top

.course-builder-item-progress
  margin-top: 1em

  .percent
    font-size: 0.7em
    margin: 5px 0

  md-progress-linear
    height: 12px

  .md-container
    background-color: white

  .md-mode-determinate
    height: 12px
    border-radius: 3px
    border: 1px solid #707070

    .md-bar
      height: 12px
      background-color: #B2BB21

.course-builder-item-actions
  width: 20%
  text-align: center

.course-builder-btn
  height: 35px
  max-height: 35px
  min-height: 35px
  min-width: 60px
  font-size: 0.7em
  line-height: 35px
  border-radius: 5px
  text-align: center

  &.course-btn-favorite
    height: 25px
    max-height: 25px
    min-height: 25px
    min-width: 50px
    margin: 0
    font-size: 0.6em
    line-height: 25px
    border-radius: 5px
    text-align: center
    margin-bottom: 0.5em
    color: white
    background-color: #70C6DD

    &:hover, &.md-focused
      background-color: #309cb9 !important

  &.course-btn-loading
    color: white
    background-color: #CCCCCC

    &:hover, &.md-focused
      color: white
      background-color: #CCCCCC

  &.course-btn-start
    color: white
    background-color: #195695

    &:hover, &.md-focused
      background-color: #04407d !important

  &.course-btn-resume
    color: white
    background-color: #F3981E

    &:hover, &.md-focused
      background-color: #d37700 !important

  &.course-btn-complete
    color: white
    background-color: #70C6DD

    &:hover, &.md-focused
      background-color: #309cb9 !important

  &.course-btn-download
    color: white
    background-color: #AEAEAE

    &:hover, &.md-focused
      background-color: #7e7373 !important

@media (max-width: 767px)
  .modal.fade.ng-isolate-scope.modal-video.in .modal-dialog
    width: 96% !important

  .course-builder
    padding: 1em 1em 7em 1em

  .course-builder-btn, .course-item-progress
    margin: 1em 0

  .action-items
    padding: 0 0 16px 16px

  .course-builder-btn
    height: 45px
    max-height: 45px
    min-height: 45px
    min-width: 150px
    font-size: 1em
    line-height: 45px

  .course-builder-item
    padding: 0

    &.text-only
      margin: 0

  .course-builder-item-info
    padding: 1em

  .course-builder-item-actions
    width: 100%
    padding: 8px

    // &.start
    //   background-color: #EBFBFF

    // &.resume
    //   background-color: #FFF2E0

    // &.complete
    //   background-color: #E8F3FF
